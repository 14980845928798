<template>
<v-container fluid fill-height v-if="show">
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Контакти</h1>
        </v-card-title>

        <!-- <pre>{{list[3]}}</pre> -->
        <v-data-table class="my-4" hide-default-footer multi-sort :loading="loading" :headers="filteredHeaders" disable-pagination :items="list">
            <template v-slot:item.actions="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.smAndDown && contactInfo.contactId != item.id" :disabled="contactInfo.realUserId ? true : false ">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon title="Підтвердити актуальність данних контакта" @click="confirmCard(item)" v-if="!item.is_confirmed" :disabled="contactInfo.realUserId ? true : false ">
                    <v-icon class="link--text pl-1">mdi-checkbox-blank-outline</v-icon>
                </v-btn>
                <v-icon class="link--text ml-2" title="Контакт підтверджений" v-else>mdi-checkbox-marked-outline</v-icon>
            </template>
            <template v-slot:item.edit="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.mdAndUp && contactInfo.contactId != item.id" :disabled="contactInfo.realUserId ? true : false ">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.gender="{ item }">
                <v-icon v-if="item.gender == 'female'" color="pink accent-2">mdi-human-female</v-icon>
                <v-icon v-else color="indigo accent-3">mdi-human-male</v-icon>
            </template>
            <template v-slot:item.is_saldo="{ item }">
                <v-icon v-if="item.is_saldo" color="green">mdi-check</v-icon>
                <v-icon v-else color="primary">mdi-minus</v-icon>
            </template>
            <template v-slot:item.position="{ item }">
                <span v-html="item.position"></span>
            </template>
        </v-data-table>
    </v-card>

    <v-dialog v-model="editDialog" persistent width="700" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
        <v-system-bar dark>
            <v-spacer></v-spacer>
            <v-icon @click="editDialog=false">mdi-close</v-icon>
        </v-system-bar>
        <v-card tile>
            <v-card-title class="headline">
                Карточка Контакта
            </v-card-title>
            <v-card-text class="text-right font-weight-bold">
                Номер: {{editData.id}}
            </v-card-text>
            <v-card-text>
                <!-- {{editData}} -->
                <v-text-field label="ПІБ" outlined v-model="editData.name"></v-text-field>
                <v-radio-group row v-model="editData.gender">
                    <v-radio label="Чол." value="male"></v-radio>
                    <v-radio label="Жiн." value="female"></v-radio>
                </v-radio-group>
                <v-text-field label="Посада" outlined v-model="editData.position"></v-text-field>
                <v-text-field label="Контактний тел. (для SMS)" outlined v-model="editData.tel_mobile" placeholder="+38X XX XXX XXXX" v-mask="maskTel"></v-text-field>
                <v-text-field label="Контактний тел. (додатк.)" outlined v-model="editData.tel"></v-text-field>
                <v-text-field label="E-mail" outlined v-model="editData.email"></v-text-field>
                <v-textarea rows="3" append-icon="mdi-comment" outlined auto-grow label="Ваш коментар" v-model="editData.comment"></v-textarea>
                <v-subheader class="text-subtitle-2">Доступ на В2В-порталі:</v-subheader>
                <v-checkbox label="Підтвердження звірок" v-model="editData.is_saldo" class="mt-0"></v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="sendConcactInfoData()">
                    Надiслати
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="editDialog=false">
                    Закрити
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="600">
        <v-card>
            <v-card-title class="headline">
                Підтвердження
            </v-card-title>

            <v-card-text>
                <p>Ви підтверджуєте, що вся інформація про Контакт 100% актуальна.</p>
                <p>Після підтвердження даному контакту будуть зняті обмеження у доступах на порталі (у межах наданих повноважень).</p>
                <p>Продовжити?</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="confirmContactData()">
                    Так
                </v-btn>
                <v-btn text @click="confirmDialog = false">
                    Нi
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>

import {
    mapActions
} from 'vuex'
import {
    mask
} from 'vue-the-mask'

export default {
    directives: {
        mask
    },
    components: {},
    data: () => ({
        show: false,
        maskTel: '+38# ## ### ####',
        editDialog: false,
        confirmDialog: false,
        editData: {},
        confirmData: {},
        headers: [{
                text: '',
                align: 'left',
                sortable: false,
                value: 'actions',
                width: '2%',
                selected: true,
            },
            {
                text: 'Номер',
                align: 'left',
                sortable: true,
                value: 'id',
                width: '6%',
                selected: true,
            },
            {
                text: 'ПІБ',
                align: 'center',
                value: 'name',
                selected: true,
                width: '20%',
            },
            {
                text: 'Стать',
                align: 'center',
                value: 'gender',
                selected: true,
                width: '2%',
            },
            {
                text: 'Посада',
                align: 'center',
                value: 'position',
                selected: true,
                width: '15%',
            },
            {
                text: 'Контактний тел. (для SMS)',
                align: 'center',
                value: 'tel_mobile',
                selected: true,
                width: '15%',
            },
            {
                text: 'Контактний тел. (дод.)',
                align: 'center',
                value: 'tel',
                selected: true,
                width: '15%',
            },
            {
                text: 'E-mail',
                align: 'center',
                value: 'email',
                width: '15%',
                selected: true,
            },
            {
                text: 'Підтвердження звірок',
                align: 'center',
                value: 'is_saldo',
                width: '10%',
                selected: true,
            },
            {
                text: '',
                align: 'left',
                sortable: false,
                value: 'edit',
                width: '2%',
                selected: true,
            },

        ],

    }),
    props: {},
    methods: {
        ...mapActions(['touch', 'getContactInfo', 'getContactsList', 'sendConcactInfo', 'confirmContact', 'setSnackbar']),
        editCard(item) {
            this.editDialog = true
            for (let el in item) {
                this.editData[el] = item[el]
            }
        },
        confirmCard(item) {
            this.confirmDialog = true
            for (let el in item) {
                this.confirmData[el] = item[el]
            }
        },
        sendConcactInfoData() {
            this.touch()
                .then(() => {
                    this.sendConcactInfo(this.editData)
                        .then(data => {
                            this.getContactsList()
                            this.setSnackbar(['success', 'Дякуємо. Повідомлення про нові данні про контакт надіслано менеджеру'])
                        })
                    this.editDialog = false
                })
                .catch(error => console.error(error))
        },
        confirmContactData() {
            this.touch()
                .then(() => {
                    this.confirmContact(this.confirmData)
                        .then(data => {
                            this.getContactsList()
                            this.setSnackbar(['success', 'Контакт пiдтверджено'])
                        })
                    this.confirmDialog = false

                })
                .catch(error => console.error(error))
        }
    },
    created() {
        this.getContactInfo()
            .then(data => {
                if (data.isMain && !data.isSellToEmployee) {
                    this.getContactsList()
                    this.$vuetify.goTo(0)
                    this.show = true

                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))

    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        list() {
            return this.$store.state.main.contactsList
        },
        loading() {
            return this.$store.state.main.loading
        },
        filteredHeaders() {
            return this.headers.filter(h => {
                return h.selected && !h.hidden
            })
        },

    }
}
</script>

<style>

</style>
