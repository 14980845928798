<template>
    <ContactsPage />
</template>
<script>

import ContactsPage from '../components/ContactsPage.vue'

export default {
    components: {
        ContactsPage
    },
}
</script>

